import React , { useEffect }from "react";
import Navbar from "../../helpers/Navbar";
import Footer from "../../helpers/Footer";
import { NavLink, useNavigate} from "react-router-dom";
import "./demopage.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../redux/reducers/profileSlice";
import {snackbarContext} from '../../helpers/Snakbar'
import { clearUser } from '../../../redux/reducers/authSlice';
import { clearError } from '../../../redux/reducers/profileSlice';

const Demohomepage = () => {
  const setStateSnackbarContext=React.useContext(snackbarContext)
  const { weddingData: profileData, error:backendError } = useSelector(
    (state) => state.profile
  );
  const {user} = useSelector(state => state.auth);
  const dispatch = useDispatch();
   const navigate=useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getProfile());
  }, []);

  useEffect(()=>{
    if (backendError === "session expired" || backendError==="pls login to acess the resource") {
        setStateSnackbarContext(true, "session expired please login again", "error");
        dispatch(clearError())
        dispatch(clearUser())
        navigate("/login")
    }else if (backendError) {
        setStateSnackbarContext(true, backendError, "error");
        dispatch(clearError());
      }
},[backendError])

if(user && user.hasEnded){
  navigate("/display/notfound")
  }


  const themes = [
    { id: "theme1", previewId: "o1rwb5qjm", name: "Pink Royale",image:"https://res.cloudinary.com/depixc8dq/image/upload/v1730636153/aamantran%20assets/theme1v2_gwmxrh.jpg" },
    { id: "theme2", previewId: "q3usxsev0", name: "Timeless Grace",image:"https://res.cloudinary.com/depixc8dq/image/upload/v1726389611/aamantran%20assets/theme2_lwfsob.jpg" },
    { id: "theme3", previewId: "leuwt9mfh", name: "Modern Chic",image:"https://res.cloudinary.com/depixc8dq/image/upload/v1730636492/aamantran%20assets/theme3v2_s1lisa.jpg" },
    { id: "theme4", previewId: "zhncc5gdc", name: "Royal Blossom",image:"https://res.cloudinary.com/depixc8dq/image/upload/v1718954818/aamantran%20assets/theme11_cwszsl.jpg" },
    { id: "theme5", previewId: "20hms3rim", name: "Boho Style",image:"https://res.cloudinary.com/depixc8dq/image/upload/v1718954817/aamantran%20assets/printbj3_ni2jux.jpg" },
    { id: "theme6", previewId: "bbqmzgbca", name: "Ethereal Blue",image:"https://res.cloudinary.com/depixc8dq/image/upload/v1718954800/aamantran%20assets/print7_dicfa0.jpg" },
    // { id: "theme7", previewId: "behevd1mc", name: "Elegant Floral",image:"https://res.cloudinary.com/dtg40vnf7/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1738667566/Screenshot_2025-02-04_164152_fqldmf.png" },
    { id: "theme7", previewId: "behevd1mc", name: "Elegant Floral",image:"https://res.cloudinary.com/depixc8dq/image/upload/v1739985042/aamantran%20assets/theme7cover_ax4rih.jpg" },
  ];

  const selectedTheme = themes.find((theme) => theme.id === profileData?.theme);
  const otherThemes = themes.filter((theme) => theme.id !== profileData?.theme);

  return (
    <>
    <Navbar />
    
    {/* If user has NOT selected a theme yet, show all themes normally */}
    {!profileData?.theme ? (
      <>
        <h2 className="explore-theme-heading">Explore Stunning Invitation Themes</h2>
        <div className="demo-container">
          {themes.map((theme, index) => (
            <div key={index} className="theme-card">
              <div className="theme-image">
                <img src={theme.image} alt={theme.name} />
                <div className={`theme-name-overlay  ${theme.name === "Ethereal Blue" ? "white-text" : ""} `} >{theme.name}</div>
              </div>
              <NavLink to={`/preview/${theme.previewId}?theme=${theme.id}`} className="view-demo-button">
                View Demo <ArrowForwardIosIcon className="view-demo-icon" />
              </NavLink>
            </div>
          ))}
        </div>
      </>
    ) : (
      <>
        {/* If user has selected a theme, show selected at top */}
        <h2 className="explore-theme-heading">Your Selected Theme</h2>
        <div className="demo-container">
          <div className="theme-card selected-theme">
            <div className="theme-image">
              <img src={selectedTheme.image} alt={selectedTheme.name} />
              <div className={`theme-name-overlay  ${selectedTheme.name === "Ethereal Blue" ? "white-text" : ""} `}>{selectedTheme.name}</div>
              <div className="selected-tag">Your Selected Theme</div>
            </div>
            <NavLink to={`/details`} className="view-demo-button selected">
              Go to Details <ArrowForwardIosIcon className="view-demo-icon" />
            </NavLink>
          </div>
        </div>

        <h2 className="explore-theme-heading">Explore Other Themes</h2>
        <div className="demo-container">
          {otherThemes.map((theme, index) => (
            <div key={index} className="theme-card">
              <div className="theme-image">
                <img src={theme.image} alt={theme.name} />
                <div className={`theme-name-overlay  ${theme.name === "Ethereal Blue" ? "white-text" : ""} `}>{theme.name}</div>
              </div>
              <NavLink to={`/preview/${theme.previewId}?theme=${theme.id}`} className="view-demo-button">
                View Demo <ArrowForwardIosIcon className="view-demo-icon" />
              </NavLink>
            </div>
          ))}
        </div>
      </>
    )}

    <Footer />
  </>
  );
};

export default Demohomepage;
